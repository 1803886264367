import {Author, Hero, Layout, Text} from "components/BlogLayout";
import SEO                          from "components/seo";
import React                        from "react";
import {useArticlesData}            from "shared/Hooks/Articles/useArticlesData";
import {ARTICLE_17}                 from "shared/Types/articles";
import {List}                       from "../../components/BlogLayout";
import {ImageWithCaption}           from "../../components/BlogLayout";

const Article_17 = () => {
  const data = useArticlesData(ARTICLE_17);
  return (
    <Layout>
      <SEO title={data.SEO_title} description={data.seo} image={data.image}/>
      <Hero data={data}/>
      <Text data={data.text.first}/>
      <ImageWithCaption data={data.imagesWithCaption.first}/>
      <Text data={data.text.second}/>
      <Text data={data.text.third}/>
      <Text data={data.text.fourth}/>
      <List data={data.list.first}/>
      <ImageWithCaption data={data.imagesWithCaption.second}/>
      <Text data={data.text.fifth}/>
      <Author data={data.authors}/>
    </Layout>
  );
};

export default Article_17;
